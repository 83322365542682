import React, {Component} from 'react';

class Footer extends Component{
    render(){
        return(
            <div id="footer">
                <div><i class="far fa-copyright"></i>Ben Harter-Murphy</div>
            </div>
        )
    }
}
export default Footer 