import React, {Component} from 'react';

class Resume extends Component{
    render(){
        return(
            <div id="resume">
                <h1>RESUME</h1>
                <p>It's a Link Too!</p>
                <a href="https://resume.creddle.io/resume/ikk1mkeet2m"><img src="bhm_creddle_res_oct2021 copy.jpg" alt="resume"/></a><br></br>
            </div>
        )
    }
}
export default Resume 