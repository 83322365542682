import React, {Component} from 'react'
import Home from '../components/Home'

class HomeContainer extends Component{
    render(){
        return(
            <div id="home-div">
                <Home />
            </div>
        )
    }
}
export default HomeContainer